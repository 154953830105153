:root {
  --first-color: rgb(27, 27, 27);
  --second-color: white;
  --third-color: rgb(225, 119, 48);
}

body {
  background-color: var(--first-color);
  color: var(--second-color);
}

#root {
  font-family: Arial, Helvetica, sans-serif;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  z-index: 1000;
}

.nav-items {
  list-style: none;
  display: flex;
  margin-right: 21px;
}

.nav-items > li:hover {
  background-color: var(--second-color);
  color: var(--first-color);
}

ul {
  padding: 0;
}

.home {
  height: fit-content;
}
.nav-items > li {
  padding: 10px;
  font-size: 1rem;
  width: 60px;
}

.nav-items > li:hover {
  cursor: pointer;
}
.nav-items > div:hover {
  cursor: pointer;
}
.right {
  margin-right: 50px;
  align-self: flex-start;
}
.left {
  margin-left: 50px;
  align-self: flex-start;
}

@keyframes showDrop {
  from {
    height: 41px;
  }
  to {
    height: fit-content;
  }
}
@keyframes hideDrop {
  from {
    height: fit-content;
  }
  to {
    height: 41px;
  }
}
.showDrop {
  animation-name: showDrop;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
.hideDrop {
  animation-name: hideDrop;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
.dropdown {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 100;
  background-color: var(--first-color);
  color: var(--second-color);
}

.dropdown > li {
  padding: 10px;
}

.dropdown > li:hover {
  background-color: var(--second-color);
  color: var(--first-color);
}
@keyframes showHam {
  from {
    height: 64px;
  }
  to {
    height: 560px;
  }
}

@keyframes hideHam {
  from {
    height: 560px;
  }
  to {
    height: 64px;
  }
}

.show {
  animation-name: showHam;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
.hide {
  animation-name: hideHam;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

.ham {
  height: 64px;
  overflow: hidden;
  z-index: 20;
}

.hamImg {
  filter: invert();
  float: right;
  width: 32px;
  padding: 16px;
}
.ham-items > li {
  font-size: 1.5rem;
  padding: 15px;
  background-color: var(--first-color);
}

.ham-items > li:hover {
  background-color: var(--second-color);
  color: var(--first-color);
}
.burg {
  display: none;
}

.pageContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  min-height: 89vh;
  margin: 2px;
}

.trim2 {
  position: fixed;
  overflow: hidden;
  width: calc(
    100vw - 20px
  ); /* Adjusted width to 100% of the viewport width minus 20px */
  height: 90vh;
  top: 70px;

  z-index: -5;
}

.profilePic2 {
  position: relative;
  left: 10px;
  /* right: 100px; */
  bottom: 100px;
  height: 1500px;
  z-index: -5;
}

.intro {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 6px;
  height: fit-content;
  /* opacity: 0.5; */
  /* background-color: transparent; */
}

.trim {
  position: relative;
  overflow: hidden;
  width: 40vw;
  margin-right: 60px;
}
.profilePic {
  position: relative;
  right: 180px;
  bottom: 20px;
  z-index: -2;
  width: 60vw;
  height: auto;
}

.myName {
  font-size: 1.7rem;
  color: rgb(236, 236, 236);
  padding-bottom: 0px;
  margin-top: 25px;
}

h1 {
  margin: 0;
  padding: 0;
}
.homeText {
  margin-top: 0px;
  margin-left: 50px;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 16px;
  padding-bottom: 60px;
}

.homeText1 {
  font-size: 3.5rem;
  font-weight: 550;
  padding-bottom: 0px;
  color: var(--second-color);
}
.homeText2 {
  font-size: 1.2rem;
  /* color: var(--first-color); */
  color: rgb(236, 236, 236);
  margin-top: 10px;
  /* margin-bottom: 220px; */
  line-height: 1.6;
  flex: 1;
}

.footer {
  text-align: center;
}

.socialLinkPic {
  width: 30px;
  margin-left: 2px;
  margin-right: 2px;
  filter: invert();
}

.socialLinkPic:hover {
  transform: scale(1.6);
}

.policy {
  display: inline;
}
.policy:hover {
  cursor: pointer;
  font-weight: 600;
}

iframe {
  width: 96vw;
  height: calc(100vh - 110px);
  border: none;
}

.upbeat {
  display: block;
  position: relative;
  max-width: 550px;
  margin: 0 auto;
  top: -50px;
  height: 90vh;
  z-index: 1;
  border-radius: 13px;
}

a {
  text-decoration: none;
  color: inherit;
}

.socialLinks {
  text-align: center;
  height: 30px;
}

.contactForm {
  height: 60vh;
  width: 57vw;
  margin: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px 10px;
}

.contactForm > div {
  margin: 10px;
  margin-left: 50px;
}

.contactLets {
  font-size: 2rem;
  color: var(--third-color);
}
.contactInput {
  background-color: rgb(77, 76, 76);
  color: rgb(221, 221, 221);
  min-width: 169px;
}

.contactMsg {
  flex: 1;
  display: flex;
  align-items: stretch;
  gap: 10px;
  min-height: 100px;
}
.green {
  color: var(--third-color);
}

.contactName {
  display: flex;
  gap: 29px;
}

.contactMail {
  display: flex;
  gap: 33px;
}

textarea {
  flex-grow: 1;
}

.contactBtn {
  width: 80px;
  align-self: flex-end;
  margin: 0 10px 10px;
  background-color: var(--first-color);
  color: var(--second-color);
  font-family: Arial, Helvetica, sans-serif;
  padding: 2px;
  border-color: rgb(246, 246, 246);
  border: solid 1px var(--second-color);
}
.contactBtn:hover {
  cursor: pointer;
  background-color: var(--second-color);
  color: var(--first-color);
}

.thankyoupage {
  display: flex;
  flex-direction: column;
  height: 82vh;
}
.tnx {
  text-align: center;
  margin: 30vh auto 45vh;
  font-size: 2.2rem;
  font-weight: 100;
}
.tnxSpan {
  color: var(--third-color);
}

.spotifyPlayerLong {
  display: block;
  margin: 0 auto 20px;
  width: 72.175%;
  height: 660px;
}
.RMtxt {
  margin: 31px auto 0px;
  display: block;
  width: 72.175%;
  line-height: 1.6;
  font-size: 1.1rem;
  color: rgb(222, 222, 222);
}

.RMGheader {
  display: inline-block;
  margin-left: 13.9125%;
  margin-bottom: 10px;
  font-size: 3rem;
  color: rgb(225, 225, 225);
}
.groupPic {
  display: block;
  width: 72.175%;
  margin: 0 auto;
}

.RMGimgtxt {
  display: flex;
  margin: 30px auto 20px;
  width: 72.175%;
}
.ewiImg {
  width: 200px;
}
.RMGtext {
  margin: 31px auto 0px;
  display: block;
  width: 75%;
  line-height: 1.6;
  font-size: 1.1rem;
  color: rgb(222, 222, 222);
}

.RMGtext1 {
  margin: 0px auto 20px;
  display: block;
  width: 75%;
  line-height: 1.6;
  font-size: 1.1rem;
  color: rgb(222, 222, 222);
}
.spotifyPlayer {
  display: block;
  margin: 0 auto 20px;
  width: 72.175%;
  height: 460px;
}

.RMGvid {
  display: block;
  width: 72.175%;
  height: 45vw;
  margin: 30px auto;
}

.musicComp {
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: space-between;
  width: 87%;
  margin: 1px auto;
}

.artlist {
  display: block;
  width: fit-content;
  margin: 15px auto;
  font-size: 1.2rem;
}
.artlistImg {
  height: 1rem;
}
.yellow {
  color: rgb(255, 210, 8);
}

.trackArt {
  height: 40px;
  width: 40px;
}

.trackTitleAlbum {
  display: flex;
  width: 30%;
  justify-content: space-between;
}
.pauseBtn {
  width: 30px;
  height: 30px;
  filter: invert();
}
.playBtn {
  filter: invert();
}

input[type="range"] {
  background-color: var(--first-color);
  height: 23px;
  -webkit-appearance: none;
  appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  box-shadow: 0px 0px 2px #ffffff;
  background: #f7f7f7;
  border-radius: 0px;
  border: 0px solid #ffffff;
}
input[type="range"]::-webkit-slider-thumb {
  height: 17px;
  width: 3px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  box-shadow: 0px 0px 2px #ffffff;
}
input[type="range"]::-moz-range-thumb {
  height: 17px;
  width: 3px;
  background: #f8f8f8;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: var(--second-color);
}
input[type="range"]::-ms-fill-lower {
  box-shadow: 0px 0px 2px #000000;
}
input[type="range"]::-ms-fill-upper {
  box-shadow: 0px 0px 2px #000000;
}
input[type="range"]::-ms-thumb {
  margin-top: 1px;
  height: 17px;
  width: 3px;
  background: #000000;
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #3071a9;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #3071a9;
}

.volume {
  width: 78px;
  display: flex;
}

.genre {
  color: rgb(154, 154, 154);
}

.volumeIcon {
  width: 18px;
  margin-right: 3px;
  filter: invert();
}

.onkeyWelcome {
  position: absolute;
  top: 85px;
  left: 25%;
  margin: auto;
  width: 50%;
  z-index: 2;
  background-color: var(--first-color);
}

.xBtn {
  position: relative;
  top: 10px;
  left: 10px;
  font-weight: 600;
  color: rgb(216, 216, 216);
  padding: 2px;
  background-color: rgb(44, 44, 44);
  z-index: 1;
}

.xBtn:hover {
  cursor: pointer;
}
.welcomeBtn {
  font-weight: 500;
  font-size: 1rem;
  border: 1px solid var(--second-color);
  color: rgb(223, 223, 223);
  padding: 7px;
  background-color: var(--first-color);
  display: inline-block;
  left: 40.5%;
  position: relative;
  margin-bottom: 10px;
}
.welcomeBtn:hover {
  cursor: pointer;
  color: var(--first-color);
  background-color: var(--second-color);
}

.coverImg {
  width: 100%;
  /* height: ; */
  position: relative;
  top: -24px;
  z-index: 0;
}

.onkeyText {
  padding: 10px 30px 10px 35px;
  line-height: 1.5;
  font-size: 1rem;
  color: rgb(208, 208, 208);
}

.aa {
  margin-left: 25px;
}

.bb {
  margin-left: 25px;
}

.cc {
  margin-left: 25px;
}

.dd {
  margin-left: 25px;
}

.coockieMsg {
  position: fixed;
  display: flex;
  justify-content: space-around;
  bottom: 0px;
  z-index: 10;
  width: 100%;
  background-color: var(--first-color);
}

.coockieText {
  padding: 8px 19px 8px 19px;
  width: fit-content;
  font-size: 1rem;
  color: rgb(154, 154, 154);
  display: inline-block;
  margin: 3px;
  vertical-align: middle;
  text-align: justify;
}

.coockieBtn {
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: center;
  /* color: ; */
  font-weight: 500;
  font-size: 1rem;
  color: rgb(2, 2, 2);
  background-color: #f7f7f7;
  padding: 6px;
  margin: 5px;
}
.coockieBtn:click {
  cursor: pointer;
  color: rgb(110, 110, 110);
}
.coockieBtn:hover {
  cursor: pointer;
  color: rgb(110, 110, 110);
}

.metContainer {
  display: flex;
  justify-content: space-between; /* Space items evenly with space between */
  position: fixed;
  top: 45vh;
  left: 0;
  right: 0;
  padding: 0 20px; /* Adjust horizontal padding as needed */
  z-index: 2;
}
.appStore {
  width: 100px;
  padding-top: 7px;
  padding-bottom: 6px;
  left: 68px;
  padding-left: 62px;
}
.playStore {
  width: 120px;
  padding-top: 0px;
  right: 50px;
  padding-right: 55px;
}

.havila {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
}

.havilaTxt1 {
  font-size: 72px;
  text-align: center;
}
.havilaTxt2 {
  direction: rtl;
  text-align: center;
  letter-spacing: 0.5px;
  line-height: 1.5;
}
.havilaItem {
  padding: 5px;
  width: 250px;
  border-radius: 20px;
  border-color: rgb(83, 83, 83);
  border-width: 3px;
  border-style: solid;
  margin: 10px;
}

.flashContainer {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center;
  width: 80%;
  margin: auto;
}
.flashFeature {
  width: 100%;
  /* height: 300px; */
}
.flashIcon {
  display: inline;
  width: 80px;
  border-radius: 15px;
  margin-right: 35px;

  -webkit-box-shadow: 0px 0px 10px 10px rgba(185, 185, 185, 0.5);
  box-shadow: 0px 0px 10px 10px rgba(185, 185, 185, 0.5);
}
.flashcardsH1 {
  font-size: 3.5rem;
}
.flashItem {
  margin-top: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.6;
  letter-spacing: 0.5px;
}

.flashCard {
  font-size: 6rem;
  border: 3px solid #ffffff;
  border-radius: 13px;
  padding: 20px;
  margin-left: 40px;
  -webkit-box-shadow: 0px 0px 10px 10px rgba(185, 185, 185, 0.3);
  box-shadow: 0px 0px 10px 10px rgba(185, 185, 185, 0.3);
  transform: rotate(4deg);
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.flashCard2 {
  font-size: 6rem;
  border: 3px solid #ffffff;
  border-radius: 13px;
  padding: 20px;
  margin-right: 40px;
  -webkit-box-shadow: 0px 0px 10px 10px rgba(185, 185, 185, 0.3);
  box-shadow: 0px 0px 10px 10px rgba(185, 185, 185, 0.3);
  transform: rotate(-4deg);
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.flashScreenshot {
  border: 3px solid #303030;
  border-radius: 13px;
  margin: 70px;
  width: 200px;
  -webkit-box-shadow: 0px 0px 10px 10px rgba(185, 185, 185, 0.3);
  box-shadow: 0px 0px 10px 10px rgba(185, 185, 185, 0.3);
}
@media screen and (max-width: 819px) {
  .burg {
    display: block;
  }
  .nav {
    display: none;
  }
  .intro {
    flex-direction: column;
    height: fit-content;
  }

  .profilePic {
    right: 0;
    width: 100%;
  }
  .trim {
    margin-left: 50px;
    max-width: 80%;
    width: 75%;
    margin-top: 20px;
    order: 1;
  }
  .homeText {
    width: 65%;
    max-width: 80%;
    order: 2;
  }
  .homeText1 {
    font-size: 2rem;
  }
  .homeText2 {
    font-size: 1rem;
  }
  .myName {
    padding-bottom: 10px;
    font-size: 1.5rem;
  }

  .progTime {
    display: none;
  }
  .volume {
    display: none;
  }
  .progSlider {
    display: none;
  }
  .trackTitleAlbum {
    width: 67%;
    justify-content: space-between;
  }
  .musicComp {
    width: 90%;
  }
  .artlist {
    width: 75%;
  }
  .genre {
    text-align: left;
  }
  .onkeyWelcome {
    top: 90px;
    width: 80%;
    left: 8%;
  }
  .ewiImg {
    display: none;
  }
  .coockieMsg {
    flex-direction: column;
  }
  .ham-items {
    z-index: 100;

    margin-top: 0px;
  }
  .appStore {
    left: 12px;
    padding-left: 12px;
  }
  .playStore {
    right: 4px;
    padding-right: 4px;
  }

  .metContainer {
    top: 0px;
    position: relative;
  }

  /* .upbeat {
  } */
}

@media screen and (orientation: portrait) and (max-width: 650px) {
  .profilePic2 {
    left: -550px;
    bottom: 0px;
    height: 1500px;
  }
  .homeText {
    width: 85%;
    margin-left: 20px;
    order: 2;
  }
  .flashcardsH1 {
    font-size: 2rem;
  }
}

@media screen and (orientation: landscape) and (max-height: 500px) {
  .profilePic2 {
    bottom: 50px;
    left: 50px;
    height: 850px;
  }
  .homeText {
    width: 60%;
    margin-left: 52px;
    order: 2;
  }
  .myName {
    font-size: 1.1rem;
  }
  .homeText1 {
    max-width: 60%;
    font-size: 2rem;
  }
  .trim2 {
    height: 600px;
    width: 90vw;
  }
}
