.videoComponent {
  display: flex;
  margin: 20px;
  align-content: flex-start;
  /* justify-content: space-between; */
}

.videoFrame {
  width: 100%;
  margin-left: 38px;
  height: 400px;
  background-color: black;
}

.videoTxt {
  margin-left: 30px;
  margin-top: 10px;
  margin-right: 38px;
  font-size: 1.05rem;
  width: 40vw;
  line-height: 1.5;
  align-self: start;
  text-align: justify;
}

@media screen and (max-width: 600px) {
  .videoComponent {
    flex-direction: column-reverse;
    margin-bottom: 35px;
  }
  .videoFrame {
    margin-left: 0px;
  }
  .videoTxt {
    margin-left: 0px;
    width: 100%;
  }
}
